<template>
    <div class="list">
        <div class="item" v-for="item in datas" :key="item.value" @click="onItemClick(item.value, item.name)">{{item.name}}</div>
    </div>
</template>

<script>
export default {
    props: {
        callback: Function
    },
    data() {
        return {
            datas: [
                { name: '婦科', value: '1' },
                { name: '兒科', value: '2' },
                { name: '傷科', value: '3' },
                { name: '皮膚科', value: '4' },
                { name: '生殖科', value: '5' },
                { name: '五官科', value: '6' },
                { name: '內科', value: '7' },
                { name: '肛腸科', value: '8' },
                { name: '針灸', value: '9' },
                { name: '外科', value: '10' },
                { name: '男科', value: '11' },
                { name: '腫瘤科', value: '12' },
            ]
        }
    },
    mounted: function(){
    },
    methods: {
        onItemClick: function(v, n) {
            this.callback && this.callback(v, n)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .list {
        position: absolute;
        left: 0;
        top: 100px;
        bottom: 0;
        right: 0;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
    }

    .list > .item {
        height: 100px;
        line-height: 100px;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
        font-size: 30px;
        font-weight: 400;
        color: #231F20;
        user-select: none;
        border-bottom: 1px solid #ededed;
    }

    .list > .item:active {
        background-color: #f7f7f7;
    }
</style>