<template>
    <div>
        <div class="question-banner">
            <h2 class="toolbar-title">咨詢醫師</h2>
        </div>
        <div class="question-form">
            <div class="form-row">
                <div class="form-item category" :data-value="question.illnesscategoryid" @click="chooseCategory">{{categoryName || '選擇科別'}}</div>
                <span class="divider"></span>
                <div class="form-item gender" :data-value="question.gender || ''" @click="chooseGender">{{genderName}}</div>
                <span class="divider"></span>
                <input type="number" class="form-item age" v-model="question.age" placeholder="患者年齡" pattern="\d*" maxlength="3"/>
            </div>
            <h3 class="form-row-head">問題標題</h3>
            <input class="form-item title" placeholder="一句話描述您的問題" v-model="question.title"/>
            <h3 class="form-row-head">填寫我的問題</h3>
            <textarea cols="30" rows="2" class="form-item content" placeholder="請描述病情（發病時間，主要症狀），曾經治療情況和效果，想得到怎樣的幫助" v-model="question.note"></textarea>
            <div class="" @click="addQuestion"> </div>
            <div class="btn-submit-question" @click="addQuestion"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '操作中...' : '提交給醫師'}}</div>
        </div>
        <van-popup
            v-model="show"
            closeable
            position="bottom"
            :style="{ height: '30%' }"
        >
            <v-category v-if="type==0" :callback="onCategoryItemClick"/>
            <v-gender v-if="type==1" :callback="onGenderItemClick"/>
        </van-popup>
    </div>
</template>

<script>
    import vCategory from '@/layouts/CategoryLayout.vue'
    import vGender from '@/layouts/GenderLayout.vue'
    import add_ask from '@/views/mixins/add_ask'

    export default {
        mixins: [add_ask],
        components: {
            vCategory,
            vGender
        },
        data(){
            return {
                type: 0,
                show: false,
                categoryName: '選擇科別',
                genderName: '患者性別',
                userInfo: this.$store.getters.userInfo,
                loading: false,
                categoryName: '',
                categorys: this.$store.getters.askCategorys || [],
                cName: '',
                cValue: '',
                ageUnits: [
                    { name: '歲', value: '1' },
                    // { name: '個月', value: '2' },
                    // { name: '週', value: '3' },
                ],
                ageUnitName: '歲',
                ageUnitValue: '1',
                question: {
                    age: '',
                    gender: 1,
                    createdby: '',
                    illnesscategoryid: '',
                    imageList: [],
                    title: '',
                    note: '',
                    ip: '',
                }
            }
        },
        mounted: function(){
            if(this.question && this.question.gender) {
                this.genderName = this.question.gender == 1 ? '男' : '女'
            }
            if(!this.$store.getters.clientId) {
                this._toast.warning(this, '未登錄，請先登錄')
                setTimeout(() => {
                    this.$router.push('/login')
                }, 1500)
            }
        },
        methods: {
            onCategoryItemClick: function(v, n) {
                this.show = false
                this.categoryName = n || '選擇科別'
                this.question.illnesscategoryid = v
            },
            onGenderItemClick: function(v, n) {
                this.show = false
                this.genderName = n || '患者性別'
                this.question.gender = v
            },
            chooseGender: function(){
              this.show = true
              this.type = 1  
            },
            chooseCategory: function(){
                this.show = true
                this.type = 0
            },
            addQuestion: function(){
                if(this.loading) return

                if(! this.question.illnesscategoryid) {
                    this._toast.warning(this, '請選擇科別')
                    return
                }

                if(this.question.age == '') {
                    this._toast.warning(this, '請輸入患者年齡')
                    return
                }
                
                if(! this.question.title.trim()) {
                    this._toast.warning(this, '請填寫問題標題')
                    return
                }
                
                if(! this.question.note) {
                    this._toast.warning(this, '請描述病情')
                    return
                }
                this.question.createdby = this.$store.getters.clientId
                this.loading = true
                this.doAddQuestion(this.question, (data, error)=>{
                    this.loading = false
                    if(!error) {
                        this._toast.success(this, '添加咨詢成功！')
                        setTimeout(() => {
                            this.$router.push('/my-ask') 
                        }, 1500)
                    } else {
                        this._toast.warning(this, error)
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .question-banner {
        display: flex;
        flex-direction: row;
        height: 194px;
        margin: 11px 24px 16px 24px;
        background-image: url(../../../assets/imgs/mobile/ask/img_bannerzx@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .question-banner > .toolbar-title {
        width: 334px;
        height: 91px;
        line-height: 91px;
        text-align: center;
        background-color: rgba(5, 42, 48, 4);
        color: #fff;
        font-weight: bold;
        font-size: 54px;
        margin: auto auto;
    }

    .question-form {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 32px 24px 0px 24px;
        margin: 0px 24px;
    }

    .question-form .form-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        justify-content: space-between;
        align-items: center;
    }

    .question-form .form-row > .form-item {
        display: inline-block;
        width: 164px;
        height: 72px;
        line-height: 72px;
        background-color: #EBF4F6;
        color: #231F20;
        font-weight: bold;
        font-size: 32px;
        text-align: center;
    }

    .question-form .form-row > .form-item[data-value=""] {
        font-weight: 400;
        color: #636363;
        font-size: 28px;
    }

    .question-form .form-row > input.form-item::placeholder {
        color: #636363;
        font-weight: 400;
        font-size: 28px;
    }

    .question-form .form-row > input.form-item {
        box-sizing: border-box;
        padding: 0px 27px;
    }

    .question-form .form-row > .divider {
        width: 1px;
        height: 36px;
        display: inline-block;
        background-color: #E0E0E0;
    }

    .question-form .form-row-head {
        font-size: 28px;
        color: #231F20;
        font-weight: bold;
        line-height: 26px;
        margin-top: 40px;
    }

    .question-form > input.form-item {
        background-color: #EBF4F6;
        box-sizing: border-box;
        color: #231F20;
        font-weight: bold;
        font-size: 32px;
        padding: 0px 15px;
        margin-top: 24px;
        height: 76px;
        line-height: 76px;
    }

    .question-form > textarea.form-item {
        background-color: #EBF4F6;
        height: 188px;
        box-sizing: border-box;
        color: #231F20;
        font-weight: bold;
        font-size: 32px;
        padding: 17px 17px;
        margin-top: 20px;
    }

    .question-form > input.form-item::placeholder,
    .question-form > textarea.form-item::placeholder {
        color: #969696;
        font-weight: 400;
        font-size: 24px;
    }

    .question-form > .btn-submit-question {
        width: 314px;
        height: 80px;
        border-radius: 8px;
        background-color: #36C4D0;
        margin: 92px auto 68px auto;
        cursor: pointer;
        font-size: 34px;
        color: #fff;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        margin-top: 14px;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */
</style>
